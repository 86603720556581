//import { useState } from "react";
//import { Fragment } from "react";
//import { createBrowserRouter, RouterProvider } from "react-router-dom";

//import BlogPage, { loader as postsLoader } from './pages/Blog';
//import HomePage from "./pages/Home";
//import PostPage, { loader as postLoader } from "./pages/Post";
//import RootLayout from "./pages/Root";
//import { lazy, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Recs from "./sults.json";
//import * as XLSX from 'xlsx';
import exportFromJSON from "export-from-json";
import MyComponent from "./WatchDog";

/*const BlogPage = lazy(() => import("./pages/Blog"));
const PostPage = lazy(() => import("./pages/Post"));
const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "posts",
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<p>Loading...</p>}>
                <BlogPage />
              </Suspense>
            ),
            loader: () =>
              import("./pages/Blog").then((module) => module.loader()),
          },
          {
            path: ":id",
            element: (
              <Suspense fallback={<p>Loading...</p>}>
                <PostPage />
              </Suspense>
            ),
            loader: (meta) =>
              import("./pages/Post").then((module) => module.loader(meta)),
          },
        ],
      },
    ],
  },
]);*/

function FlightInfo({ data }) {
  const contextKeys = Object.keys(data.context);




 


   async function componentDidMount(e) {
    e.preventDefault();
    // POST request using fetch with set headers
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":
          "j4yiti5mu2fhvbxezyyfdpj8tnrei9z3gl01qxlswi6lp5cwuwx7e4e2e7k3nsp3",
        "Connection": "keep-alive",
      },
      mode: "no-cors",
      body: JSON.stringify({
        origin: "PRG",
        destination: "BKK",
        flightType: "return",
        periodStart: "2023-11-01",
        periodEnd: "2023-12-01",
        maxTransfers: 2,
      }),
    };
    const response = await  fetch(
      "https://price-checker-api.cee-systems.com/period-calendar",
      requestOptions
    );
    if (!response.ok) {
      throw new Error("Greška u povezivanju");
    }
    const dataDog = await response.json();
    console.log(dataDog);
    
  }

  function formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZoneName: "short",
    };
    return new Intl.DateTimeFormat("en-GB", options).format(date);
  }
  function formatDateTimeZaPrvi(dateTimeString) {
    const date = new Date(dateTimeString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Intl.DateTimeFormat("en-GB", options).format(date);
  }
  const dWatch = contextKeys.map((date) =>
    data.context[date].detail.FlightSegments.map((segment) => [
      { Data: formatDateTimeZaPrvi(date) },
      { Price: data.context[date].price },
      { Number: segment.Number },
      { Operating_Carrier: segment.OperatingCarrier },
      { Departure_Time: formatDateTime(segment.DepartureTime) },
      { Origin: segment.Origin },
      { Destination: segment.Destination },
      { Plane: segment.Plane },
    ])
  );

  /*const exportExcel = (dWatch) => {
   
    const worksheet = XLSX.utils.json_to_sheet(dWatch );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "DataSheet.xlsx");
  
  }*/

  const downloadExcel = () => {
    const data = dWatch;
    const fileName = "WatchDogData";
    const exportType = exportFromJSON.types.csv;

    exportFromJSON({ data, fileName, exportType });
  };

  return (
    <div className="container">
      <button
        type="button"
        className="btn btn-primary"
        style={{ margin: "10px" }}
        onClick={downloadExcel}
      >
        Download Excel
      </button>
      <button
        type="button"
        className="btn btn-secondary"
        onClick={componentDidMount}
      >
        WatchDog Data
      </button>
      <MyComponent />
      {contextKeys.map((date, index) => (
        <div key={index}>
          <h2 className="text-center">
            Results for: {formatDateTimeZaPrvi(date)}
          </h2>
          <h3 className="text-center">Price: {data.context[date].price}</h3>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Number</th>
                <th scope="col">Operating Carrier</th>
                <th scope="col">Departure Time</th>
                <th scope="col">Arrival Time</th>
                <th scope="col">Origin</th>
                <th scope="col">Destination</th>
                <th scope="col">Plane</th>
              </tr>
            </thead>

            {data.context[date].detail.FlightSegments.map((segment, index) => [
              <tbody key={index}>
                <tr>
                  <th scope="row">{index}</th>
                  <td>{segment.Number}</td>
                  <td>{segment.OperatingCarrier}</td>
                  <td>{formatDateTime(segment.DepartureTime)}</td>
                  <td>{formatDateTime(segment.ArrivalTime)}</td>
                  <td>{segment.Origin}</td>
                  <td>{segment.Destination}</td>
                  <td>{segment.Plane}</td>
                </tr>
              </tbody>,
            ])}
          </table>
          ,
        </div>
      ))}
    </div>
  );
}

function App() {
  return (
    <div>
      <FlightInfo data={Recs} />
    </div>
  );
}

export default App;
