import React, { Component } from "react";

class MyComponent extends Component {
  async postData() {
    const url = "https://price-checker-api.cee-systems.com/period-calendar"; // Replace with your API URL
    const headers = {
      //Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "j4yiti5mu2fhvbxezyyfdpj8tnrei9z3gl01qxlswi6lp5cwuwx7e4e2e7k3nsp3", // Replace with your authorization token if needed
    };

    const data = {
      "origin": "PRG",
      "destination": "BKK",
      "flightType": "return",
      "periodStart": "2023-11-01",
      "periodEnd": "2023-12-01",
      "maxTransfers": 2,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
        mode: "no-cors",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // If the response is JSON, you can parse it
      const responseData = await response.json();

      // Handle the responseData as needed
      console.log(responseData);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  render() {
    return (
      <div>
        <button onClick={this.postData}>Send POST Request</button>
      </div>
    );
  }
}

export default MyComponent;
